$colors: (
  "white": #ffffff,
  "black": #171717,
  "darkblue": #1C252C,
  "darkblue-2": #232B32,
  "darkblue-3": #273037,
  "transparent": transparent,
  "gray-1": #9ca4ab,
  "gray-2": #d1d8dd,
  "gray-3": #78828a,
  "gray-5": #92929d,
  "gray-6": #29282f,
);

// Default Font Size
$base-font-size: 16px;

// Responsive Breakpoint
$br-xxs: 375px;
$br-xx: 425px;
$br-xs: 480px;
$br-mm: 512px;
$br-sm: 576px;
$br-md: 768px;
$br-lg: 992px;
$br-xl: 1140px;
$br-xxl: 1200px;
