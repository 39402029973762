.wrapper {
  &__navbar {
    border-bottom: 1px solid #FFFFFF2B;
    .items {
      padding: 0 2rem;
      height: 122px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &:last-child {
        width: 245px;

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0.08) 10.38%,
            rgba(255, 255, 255, 0) 92.99%
          );
          z-index: 0;
        }
      }

      @media screen and(max-width: 1024px) {
        height: 90px;
        &:last-child {
          width: 200px;
        }
      }
      @media screen and(max-width: 1024px) {
        &:last-child {
          width: initial;
        }
      }
    }
    .center {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
